import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';
import SubscribeNewPlanPage from 'src/pages/payment';
import PaymentFail from 'src/pages/payment/fail';
import PaymentSuccess from 'src/pages/payment/success';

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('src/pages/404'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: 'payment', element: <SubscribeNewPlanPage /> },
      { path: 'payment/success', element: <PaymentSuccess /> },
      { path: 'payment/fail', element: <PaymentFail /> },
      { path: '404', element: <Page404 /> },
    ],
  },
];
