import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import {
  confirmPasswordReset,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import useCustomFirebaseApp from 'src/hooks/use-firebase';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { useSnackbar } from 'notistack';
import useHandleError from 'src/hooks/use-handle-error';
import useHandleResponse from 'src/hooks/use-handle-response';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { firebaseAuth } = useCustomFirebaseApp();
  const { handleError } = useHandleError();
  const { handleResponse } = useHandleResponse();

  // LOGOUT
  const logout = useCallback(async () => {
    signOut(firebaseAuth);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      const accessToken = await firebaseAuth.currentUser?.getIdToken();
      // console.log({ accessToken });
      if (accessToken && isValidToken(accessToken)) {
        const email = firebaseAuth.currentUser.email;
        setSession(accessToken);
        const response = await axios.get(endpoints.vendor.getInstituteMe(email));
        const { data: user } = response.data;
        if (user && !user?.eligible) {
          enqueueSnackbar(user?.remarks, { variant: 'error' });
          logout();
        } else if (!response.data?.success) {
          const { isError } = handleResponse(response);
          if (isError) {
            logout();
          }
        }
        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      logout();
    }
  }, [logout]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      initialize();
    });
    return () => unsubscribe();
  }, []);

  // LOGIN
  const login = useCallback(async (email, password) => {
    // Remove previous user if exist
    if (firebaseAuth.currentUser) {
      await logout();
    }
    const firebaseResponse = await signInWithEmailAndPassword(firebaseAuth, email, password);
    console.log({ firebaseResponse });
  }, []);

  const firebaseForgotPassword = (email) => {
    return sendPasswordResetEmail(firebaseAuth, email);
  };

  const firebaseResetPassword = (oobCode, newPassword) => {
    return confirmPasswordReset(firebaseAuth, oobCode, newPassword);
  };

  const backendForgotPassword = async (email) => {
    const responseBackend = await axios.post(endpoints.auth.forgotPasswordInstitute(email), null, {
      // Need to removed it I guess
      headers: {
        Authorization: null,
      },
    });
    console.log({ responseBackend });
    return responseBackend;
  };

  const registerInstitute = useCallback(
    async ({
      email,
      password,
      instituteName,
      address,
      inChargeName,
      inChargePhoneNumber,
      phoneNumber,
      inChargeEmail,
      numberOfStudents,
      language,
      country,
      instituteType,
      sendPassword,
    }) => {
      try {
        console.log({
          email,
          password,
          instituteName,
          address,
          inChargeName,
          inChargePhoneNumber,
          phoneNumber,
          inChargeEmail,
          numberOfStudents,
          language,
          country,
          instituteType,
          sendPassword,
        });
        // Register in backend
        const data = {
          email: email,
          institute_name: instituteName,
          address: address,
          country: country,
          contact_phone_no: phoneNumber,
          person_in_charge: inChargeName,
          person_in_charge_phone_no: inChargePhoneNumber,
          person_in_charge_email: inChargeEmail,
          institute_type: instituteType,
          no_of_students: numberOfStudents,
          language_to_teach: language,
          auto_send_link: sendPassword,
        };
        const responseBackend = await axios.post(endpoints.auth.registerInstitute, data, {
          headers: {
            Authorization: null,
          },
        });
        console.log({ responseBackend });
        return responseBackend;
      } catch (e) {
        throw e;
      }
    },
    []
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = state?.user?.eligible ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      registerInstitute,
      firebaseForgotPassword,
      backendForgotPassword,
      firebaseResetPassword,
      logout,
    }),
    [
      login,
      logout,
      registerInstitute,
      backendForgotPassword,
      firebaseForgotPassword,
      firebaseResetPassword,
      state.user,
      status,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
