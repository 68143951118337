import { useSnackbar } from 'notistack';

const useHandleResponse = () => {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = (response) => {
    let variant = 'success';
    const data = response?.data;
    const isError = !!data?.error?.code;
    const success = data?.success ?? true;
    if (isError || !success) {
      variant = 'error';
    }
    enqueueSnackbar(data?.error?.message || data?.message, { variant });
    return { isError };
  };
  return { handleResponse };
};

export default useHandleResponse;
