import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// assets
import { PlanPremiumIcon } from 'src/assets/icons';
// components

// ----------------------------------------------------------------------

export default function PricingCard({ item, onPay }) {
  const { amount, currency, items } = item;
  const currencyMap = {
    USD: '$',
  };
  return (
    <Stack
      spacing={5}
      sx={{
        p: 5,
        borderRadius: 2,
        boxShadow: (theme) => ({
          xs: theme.customShadows.card,
          md: `-40px 40px 80px 0px ${alpha(
            theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
            0.16
          )}`,
        }),
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Box sx={{ width: 48, height: 48 }}>
          <PlanPremiumIcon />
        </Box>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
          {items}
        </Typography>
        {/* <Typography variant="subtitle2">{items}</Typography> */}
      </Stack>
      <Stack direction="row" justifyContent={'center'} alignItems={'center'} gap={0.5}>
        <Typography variant="h2">{currencyMap?.[currency] || currency}</Typography>
        <Typography variant="h2">{amount}</Typography>
      </Stack>
      <Button onClick={onPay} fullWidth size="large" variant="contained" color={'inherit'}>
        Pay
      </Button>
    </Stack>
  );
}

PricingCard.propTypes = {
  item: PropTypes.object,
  sx: PropTypes.object,
};
