import { useSnackbar } from 'notistack';

const useHandleError = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (e) => {
    const errorMessage = e?.message || typeof e === 'string' ? e : false;
    if (errorMessage) enqueueSnackbar(e?.message || e, { variant: 'error' });
  };
  return { handleError };
};

export default useHandleError;
