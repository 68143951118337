import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSubscribeContext } from 'src/contexts/subscription-context';
import useHandleError from 'src/hooks/use-handle-error';
import useHandleResponse from 'src/hooks/use-handle-response';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import PricingCard from './card';
import { paths } from 'src/routes/paths';
import PaymentNotFoundView from './not-found-view';

export default function NewPlanView() {
  const queryParams = useSearchParams();
  const subscriptionId = queryParams?.get('subscriptionUUID');
  const router = useRouter();
  const { getSubscriptionDetailsById } = useSubscribeContext();
  const { handleResponse } = useHandleResponse();
  const { handleError } = useHandleError();
  const [paymentObject, setPaymentObject] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const onPaymentClick = () => {
    // Remove amount override once fixed - values
    window.payhere.startPayment?.({ ...paymentObject });
  };

  if (window.payhere) {
    window.payhere.onCompleted = function onCompleted(orderId) {
      console.log({ orderId });
      router.push(paths.payment.success);
    };
    window.payhere.onDismissed = function onDismissed() {
      console.log({ msg: 'Payhere dismissed.' });
      router.push(paths.payment.fail);
    };
    window.payhere.onError = function onError(error) {
      console.log({ error });
      router.push(paths.payment.fail);
    };
  }

  useEffect(() => {
    if (subscriptionId) {
      setIsLoading(true);
      getSubscriptionDetailsById(subscriptionId)
        .then((response) => {
          const { isError } = handleResponse(response);
          if (!isError) {
            const pObject = response?.data?.data;
            console.log({ pObject });
            setPaymentObject(pObject);
          }
        })
        .catch((e) => {
          handleError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [subscriptionId]);
  const isInvalidId = !isLoading && !paymentObject;
  return (
    <Stack direction={'row'}>
      {!!paymentObject && <PricingCard item={paymentObject} onPay={onPaymentClick} />}
      {(!subscriptionId || isInvalidId) && <PaymentNotFoundView />}
    </Stack>
  );
}
