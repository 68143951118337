// @mui
import Typography from '@mui/material/Typography';
// assets
import { SentIcon } from 'src/assets/icons';
// components
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

export default function PaymentFail() {
  return (
    <>
      <Helmet>
        <title> Payment: Failed</title>
      </Helmet>
      <Typography variant="h3" color={'error.dark'} sx={{ mb: 1 }}>
        Unfortunately payment was rejected!
      </Typography>
      <Typography color={'text.secondary'} sx={{ mb: 1 }}>
        Your payment was rejected, Please try again or contact the service provider
      </Typography>
    </>
  );
}
