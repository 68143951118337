import { m } from 'framer-motion';
// @mui
import Typography from '@mui/material/Typography';
// layouts
import CompactLayout from 'src/layouts/compact';
// routes
// components
import { MotionContainer, varBounce } from 'src/components/animate';
// assets
import CheckOutIllustration from 'src/assets/illustrations/check-out-illustration';

// ----------------------------------------------------------------------

export default function PaymentNotFoundView() {
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Broken payment link!
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, your payment link is broken or invalid, please use a valid link to proceed the
            payment.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <CheckOutIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </MotionContainer>
    </CompactLayout>
  );
}
