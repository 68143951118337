import { Helmet } from 'react-helmet-async';
// sections
import NewPlanView from 'src/sections/payment/NewPlanView';

// ----------------------------------------------------------------------

export default function SubscribeNewPlanPage() {
  return (
    <>
      <Helmet>
        <title> Subscribe : New Plan</title>
      </Helmet>
      <NewPlanView />
    </>
  );
}
