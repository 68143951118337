import { createContext, useCallback, useContext, useMemo } from 'react';
import axios, { endpoints } from 'src/utils/axios';
// ----------------------------------------------------------------------

export const SubscribeContext = createContext({
  getSubscriptionDetailsById: async () => {},
});

export const useSubscribeContext = () => {
  const context = useContext(SubscribeContext);
  return context;
};

export const SubscribeProvider = (props) => {
  const { children } = props;

  const getSubscriptionDetailsById = useCallback(async (subscriptionUUID) => {
    const backendResponse = await axios.get(
      endpoints.payhere.getSubscriptionDetailsById(subscriptionUUID)
    );
    return backendResponse;
  }, []);

  const memoizedValue = useMemo(
    () => ({
      getSubscriptionDetailsById,
    }),
    [getSubscriptionDetailsById]
  );
  return <SubscribeContext.Provider value={memoizedValue}>{children}</SubscribeContext.Provider>;
};
