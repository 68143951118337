import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
const { FIREBASE_API } = require('src/config-global');

const useCustomFirebaseApp = () => {
  const firebaseApp = initializeApp(FIREBASE_API);
  const firebaseAuth = getAuth(firebaseApp);
  return { firebaseAuth, firebaseApp };
};

export default useCustomFirebaseApp;
