// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      verify: (userRole) => `${ROOTS.AUTH}/jwt/verify/${userRole}`,
      newPassword: `${ROOTS.AUTH}/jwt/new-password`,
    },
  },
  payment: {
    root: '/payment',
    success: '/payment/success',
    fail: '/payment/fail',
  },
};
