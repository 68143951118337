// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { RouterLink } from 'src/routes/components';
// assets
import { EmailInboxIcon, SentIcon } from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'src/routes/hooks';

// ----------------------------------------------------------------------

export default function PaymentSuccess() {
  return (
    <>
      <Helmet>
        <title> Payment: Success</title>
      </Helmet>
      <SentIcon sx={{ height: 96 }} />
      <Typography variant="h3" color={'success.dark'} sx={{ mb: 1 }}>
        Payment confirmed!
      </Typography>
      <Typography color={'text.secondary'} sx={{ mb: 1 }}>
        Congratulations, your payment has been successful
      </Typography>
    </>
  );
}
