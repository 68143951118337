import { alpha } from '@mui/material';

export function link(theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&.MuiTypography-root': {
            color: alpha(theme.palette.primary.main, 0.7),
          },
          [`&:hover`]: {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
  };
}
